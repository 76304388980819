import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callApi } from '../../utils/api';
import { ApplicationState } from '../../store';
import { fetchApprovedListRequest } from '../../store/approval/actions';
import moment from 'moment';
import './inactive-table.css';
import userImage from '../../images/user/user-image.png';
import pencilIcon from '../../images/icons/pencil.svg';

interface InactiveTableProps {
    showAll: boolean;
    inactiveStudents: { studentId: string; inactive: number }[];
}



const InactiveTable: React.FC<InactiveTableProps> = ({ showAll = false, inactiveStudents }) => {

    const dispatch = useDispatch();
    const approvedList = useSelector((state: ApplicationState) => state.approval.approvedList?.data || []);
    const loginState = useSelector((state: ApplicationState) => state.autherize);

    const [editingReason, setEditingReason] = useState<string | null>(null);
    const [reasonInputValues, setReasonInputValues] = useState<{ [key: string]: string }>({});

    const { token } = loginState.data;
    const filteredApprovedList = approvedList.filter(student => {
        const inactiveStudent = inactiveStudents.find(inactive => inactive.studentId === student.id);

        if (showAll) return inactiveStudent && [0, 2, 3, 4].includes(inactiveStudent.inactive);
        return inactiveStudent && [0, 2, 3, 4].includes(inactiveStudent.inactive) && student.inactiveTrack === 1;
    });



    const handleReasonClick = (userId: string, reason: string) => {
        setEditingReason(userId);
        setReasonInputValues(prev => ({ ...prev, [userId]: reason }));
    };

    const handleReasonChange = (userId: string, value: string) => {
        setReasonInputValues(prev => ({ ...prev, [userId]: value }));
    };

    const handleReasonBlur = async (userId: string) => {
        try {
            const url = `${process.env.REACT_APP_API_URL}/v1/school/update/inactive-reason`;
            const body = { userId, reason: reasonInputValues[userId] }
            await callApi('PUT', url, token, body);
            dispatch(fetchApprovedListRequest());
        } catch (error) {
            console.error('Error updating inactivity reason:', error);
        } finally {
            setEditingReason(null);
        }
    };

    const handleActionClick = async (userId: string, currentInactiveTrack: number) => {
        try {
            const url = `${process.env.REACT_APP_API_URL}/v1/school/user/toggle-inactive-track`;
            const body = { userId, currentInactiveTrack }
            await callApi('PUT', url, token, body);
            dispatch(fetchApprovedListRequest());
        } catch (error) {
            console.error('Error updating inactivity reason:', error);
        } finally {
            setEditingReason(null);
        }
    };

    const getStatusClass = (status: string) => {
        if (/^One Week \(No Activity\)$/.test(status)) return '';
        if (/^Two Weeks? \(No Activity\)$/.test(status)) return 'status-two-weeks';
        if (/^Three Weeks? \(No Activity\)$/.test(status)) return 'status-three-weeks';
        if (/^(Four|Five|Six|Seven|Eight|Nine|Ten|\d+)\sWeeks? \(No Activity\)$/.test(status)) return 'status-four-weeks';

        switch (status) {
            case 'Two Weeks':
                return 'status-two-weeks';
            case 'Three Weeks':
                return 'status-three-weeks';
            case 'Four Weeks +':
            case 'NONE':
                return 'status-four-weeks';
            default:
                return '';
        }
    };

    const getWeeksSinceRegistration = (registerDate: string) => {
        const now = moment();
        const registered = moment(registerDate);
        const weeks = Math.ceil(now.diff(registered, 'weeks', true));
        const weekWord = numberToWord(weeks);
        return `${weekWord} Week${weeks !== 1 ? 's' : ''} (No Activity)`;
    };

    const numberToWord = (num: number): string => {
        const words = ['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten'];
        return num >= 1 && num <= 10 ? words[num - 1] : `${num}`;
    };



    return (
        <table className="table-container">
            <thead>
                <tr>
                    <th className="user-table-center-nowrap">ID</th>
                    <th>Name</th>
                    <th className="user-table-center-nowrap">Nickname</th>
                    <th className="user-table-center-nowrap">Phone</th>
                    <th className="user-table-center-nowrap">Inactivity</th>
                    <th className="user-table-center-nowrap">Reason</th>
                    <th className="user-table-center-nowrap">Action</th>
                </tr>
            </thead>
            <tbody>
                {filteredApprovedList.map((student) => {
                    const inactiveStudent = inactiveStudents.find(inactive => inactive.studentId === student.id);
                    const inactivityStatus = inactiveStudent
                        ? inactiveStudent.inactive === 0
                            ? getWeeksSinceRegistration(student.register_date)
                            : ['WHAT', 'WHAT', 'Two Weeks', 'Three Weeks', 'Four Weeks +'][inactiveStudent.inactive] || `${inactiveStudent.inactive}`
                        : 'Unknown';

                    const renderActionButton = () => {
                        if (student.inactiveTrack === 1 || student.inactiveTrack === 0) {
                            const actionText = student.inactiveTrack === 1 ? 'Ignore' : 'Track';
                            return <button className="inactive-button" onClick={() => handleActionClick(student.id, student.inactiveTrack)}>{actionText}</button>;
                        }
                        return null;
                    };

                    return (
                        <tr key={`pending-${student.id}`}>
                            <td className="user-table-center-nowrap">{student.id}</td>
                            <td>
                                <img className='table-profile-image' src={student.lowPhotoUrl || userImage} alt={student.fullname} />
                                {student.fullname}
                            </td>
                            <td className="user-table-center-nowrap">{student.login}</td>
                            <td className="user-table-center-nowrap">{student.parentPhone}</td>
                            <td className="user-table-center-nowrap">
                                <span className={`${getStatusClass(inactivityStatus)}`}>{inactivityStatus}</span>
                            </td>
                            <td>
                                {editingReason === student.id ? (
                                    <div className="user-course-container">
                                        <input
                                            type="text"
                                            value={reasonInputValues[student.id] !== undefined ? reasonInputValues[student.id] : student.inactiveReason || ''}
                                            onChange={(e) => handleReasonChange(student.id, e.target.value)}
                                            onBlur={() => handleReasonBlur(student.id)}
                                            onKeyDown={(e) => { if (e.key === 'Enter') { handleReasonBlur(student.id); } }}
                                        />
                                    </div>
                                ) : (
                                    <div className="user-course-container" onClick={() => handleReasonClick(student.id, student.inactiveReason)}>
                                        {student.inactiveReason || '—'}
                                        <img src={pencilIcon} alt="Edit" onClick={() => handleReasonClick(student.id, student.inactiveReason)} />
                                    </div>
                                )}
                            </td>
                            <td className="user-table-center-nowrap">{renderActionButton()}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default InactiveTable;