import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { callApi } from '../../utils/api';
import { ApplicationState } from '../../store';
import { PlaylistTopic } from '../../types/types';
import LivestreamTable from '../../components/table/livestream-table';
import moment from 'moment';
import './index.css';

interface PlaylistProps { roomId: string | null; subjectExtend: string | undefined }



const Playlist: React.FC<PlaylistProps> = ({ roomId, subjectExtend }) => {

    const loginState = useSelector((state: ApplicationState) => state.autherize);
    const toggleState = useSelector((state: ApplicationState) => state.toggle);
    const playlistId = toggleState.toggle.split('-')[1];

    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [playlistTopics, setPlaylistTopics] = useState<PlaylistTopic[]>([]);
    const [selectedTopic, setSelectedTopic] = useState<PlaylistTopic | null>(null);



    const getPlaylistTopics = async () => {
        setLoading(true);
        setError(null);
        try {
            const { token } = loginState.data;
            const baseUrl = `${process.env.REACT_APP_API_URL}/v1/live-stream/lsplayback/topics/school?id=${playlistId}`;
            const url = subjectExtend ? `${baseUrl}&extend=${subjectExtend}` : baseUrl;
            const response = await callApi('GET', url, token);
            const topics = response.data;
            const sortedTopics = topics.sort((a: PlaylistTopic, b: PlaylistTopic) => b.id - a.id);

            setPlaylistTopics(sortedTopics);
            if (sortedTopics.length > 0) setSelectedTopic(sortedTopics[0]);

        } catch (error) {
            setError((error as Error).message || 'An unknown error occurred');
            console.error('Error fetching playlist topics:', error);
        } finally {
            setLoading(false);
        }
    };

    const extractSubjectPart = (subject: string) => {
        const match = subject.match(/(ตอนที่ \d+|Ep\.\d+)\s+(.*)/);
        return match ? match[2] : subject;
    };

    const formatStartTime = (starttime: string) => moment(starttime).format('DD MMM');

    const handleButtonClick = (topic: PlaylistTopic) => setSelectedTopic(topic);



    useEffect(() => { getPlaylistTopics() }, [playlistId]);



    return (
        <div className="content" id="playlistPageContent">
            {loading && <div>Loading...</div>}
            {error && <div>Error: {error}</div>}

            <div className="main-layout">
                {playlistTopics.length > 0 ? (
                    <div className="button-container">
                        {playlistTopics.map((topic) => (
                            <button
                                key={topic.id}
                                onClick={() => handleButtonClick(topic)}
                                className={`topic-button ${selectedTopic?.id === topic.id ? 'selected' : ''}`}
                            >
                                {extractSubjectPart(topic.subject)} – {formatStartTime(topic.starttime)}
                            </button>
                        ))}
                    </div>
                ) : (
                    !loading && <div>No topics available.</div>
                )}
                {selectedTopic && (
                    <div className="topic-details">
                        <h3>{`${selectedTopic.subject}`}</h3>
                        <div className="w-100 mb-5">
                            <LivestreamTable
                                liveId={selectedTopic.id}
                                subject={extractSubjectPart(selectedTopic.subject)}
                                startTime={selectedTopic.starttime}
                                roomId={roomId}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Playlist;
