import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { callApi } from '../../utils/api';
import { Playlist } from '../../store/toggle/types';
import { setPlaylists } from '../../store/toggle/actions';
import PulseLoader from 'react-spinners/PulseLoader';
import './table.css';
import './playlist-table.css';

type ToggleRequestBody = { id: string; extend?: string; };



const PlaylistTable = () => {

    const dispatch = useDispatch();
    const loginState = useSelector((state: ApplicationState) => state.autherize);
    const [expandedNames, setExpandedNames] = useState<Set<string>>(new Set());
    const [isExtension, setIsExtension] = useState(false);
    const [keyword, setKeyword] = useState<string>('');
    const [loading, setLoading] = useState(true);
    const [playlists, setPlaylistsLocal] = useState<Playlist[]>([]);
    const [playlistsExtension, setPlaylistsExtension] = useState<Playlist[]>([]);
    const [schoolRooms, setSchoolRooms] = useState<any[]>([]);
    const [searchResults, setSearchResults] = useState<Playlist[]>([]);
    const [searchExtension, setSearchExtension] = useState<string>('');

    const { token } = loginState.data;



    const getFirstInstructorName = (instructorNames: string) => {
        const match = instructorNames.match(/^([^,]+)/);
        return match ? match[1].trim() : instructorNames;
    };

    const getSchoolPlaylists = async () => {
        try {
            const response = await callApi('GET', `${process.env.REACT_APP_API_URL}/v1/live-stream/lsplayback/school`, token);
            const allPlaylists = response.data.map((p: Playlist) => ({ ...p, instructor: getFirstInstructorName(p.instructor) }));
            const basePlaylists = allPlaylists.filter((p: Playlist) => !p.subjectExtend);
            const extendedPlaylists = allPlaylists.filter((p: Playlist) => p.subjectExtend);
            setPlaylistsLocal(basePlaylists); setPlaylistsExtension(extendedPlaylists); dispatch(setPlaylists(allPlaylists));
        } catch (error) {
            console.error('Error fetching playlists:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchCourses = async () => {
        try {
            const response = await callApi('GET', `${process.env.REACT_APP_API_URL}/v1/school/school-room`, token);
            setSchoolRooms(response.schoolRooms);
        } catch (error) {
            console.error('Error fetching school rooms:', error);
        }
    };

    const handleCourses = async (playlistId: string, courseId: string) => {
        try {
            await callApi('PUT', `${process.env.REACT_APP_API_URL}/v1/school/playlist/course/update`, token, { playlistId, courseId });
            await getSchoolPlaylists();
        } catch (err) {
            console.error('Error updating school room ID:', err);
        }
    };

    const searchPlaylists = async () => {
        if (keyword.trim().length < 3) { alert('Keyword must be at least 3 characters long.'); return; }
        setLoading(true);
        try {
            const response = await callApi('GET', `${process.env.REACT_APP_API_URL}/v1/school/playlists/search?keyword=${encodeURIComponent(keyword)}`, token);
            const processedPlaylists = response.map((p: Playlist) => ({ ...p, instructor: getFirstInstructorName(p.instructor) }));
            setSearchResults(processedPlaylists);
        } catch (error) {
            console.error('Error searching playlists:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleAdd = async (id: string) => {
        try {
            const { token } = loginState.data;
            const url = `${process.env.REACT_APP_API_URL}/v1/live-stream/lsplayback/school/toggle`;
            const requestBody = { id, extend: searchExtension.trim() ? searchExtension : undefined };
            await callApi('POST', url, token, requestBody);
            getSchoolPlaylists(); searchPlaylists();
        } catch (error) {
            console.error('Error adding playlist:', error);
        }
    };

    const handleRemove = async (id: string) => {
        const isConfirmed = window.confirm('Are you sure you want to remove this playlist?');
        if (!isConfirmed) return;
        try {
            const { token } = loginState.data;
            const matchedExtension = playlistsExtension.find(ext => ext.subjectExtend === id);
            const requestBody: ToggleRequestBody = { id };
            if (matchedExtension) requestBody.extend = matchedExtension.id;
            await callApi('POST', `${process.env.REACT_APP_API_URL}/v1/live-stream/lsplayback/school/toggle`, token, requestBody);

            const updatedPlaylists = playlists.filter(playlist => playlist.id !== id);
            setPlaylistsLocal(updatedPlaylists); dispatch(setPlaylists(updatedPlaylists)); handleClearSearch();
        } catch (error) {
            console.error('Error removing playlist:', error);
        }
    };

    const handleClearSearch = () => { setKeyword(''); setSearchResults([]); setSearchExtension('') };

    const renderExtensionName = (playlistId: string) => {
        const fullName = getExtendedName(playlistId);
        const isExpanded = expandedNames.has(playlistId);
        const truncatedName = fullName.length > 20 ? `${fullName.substring(0, 20)}...` : fullName;

        return (
            <span onClick={() => toggleName(playlistId)} style={{ cursor: 'pointer' }} title={fullName} >
                {isExpanded ? fullName : truncatedName}
            </span>
        );
    };

    const getExtendedName = (playlistId: string) => {
        const ext = playlistsExtension.find((p: Playlist) => playlistId === p.subjectExtend);
        return ext ? ext.name : '';
    };

    const toggleName = (id: string) => {
        setExpandedNames(prev => {
            const newSet = new Set(prev);
            newSet.has(id) ? newSet.delete(id) : newSet.add(id);
            return newSet;
        });
    };

    const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => setKeyword(event.target.value);

    const handleKeywordKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && keyword.trim()) searchPlaylists();
    };



    useEffect(() => { if (loginState.data) { getSchoolPlaylists(); fetchCourses(); } }, [loginState.data]);



    return (
        <div>
            <table className="table-container">
                <thead>
                    <tr>
                        <th className="table-id">ID</th>
                        <th className="table-instructor">Instructor</th>
                        <th className="table-name">Name</th>
                        <th className="table-name">Course</th>
                        <th className="table-name">Extension</th>
                        <th className="table-add">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan={4}>
                                <div className="loader-container">
                                    <PulseLoader color="#0b7aff" />
                                </div>
                            </td>
                        </tr>
                    ) : playlists.length ? (
                        playlists.map((p) => (
                            <tr key={p.id}>
                                <td className="table-id">{p.id}</td>
                                <td className="table-instructor">{p.instructor}</td>
                                <td>{p.name}</td>
                                <td className="user-table-center-nowrap">
                                    <select value={p.roomId || ''} onChange={(e) => handleCourses(p.id, e.target.value)}>
                                        {schoolRooms.map((room) => (
                                            <option key={room.id} value={room.id}>{room.name}</option>
                                        ))}
                                    </select>
                                </td>
                                <td style={{ whiteSpace: 'nowrap' }}>
                                    {renderExtensionName(p.id)}
                                </td>
                                <td className="table-add">
                                    <button className="remove-button" onClick={() => handleRemove(p.id)}>Remove</button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={4} className="no-playlists">No Playlists Available</td>
                        </tr>
                    )}
                </tbody>
            </table>

            <div className="playlist-search-container">
                <input
                    className="playlist-search-box" type="text" value={keyword} placeholder="Search Playlist"
                    onChange={handleKeywordChange} onKeyDown={handleKeywordKeyDown}
                />
                <button onClick={searchPlaylists}>Search</button>
                {!loading && searchResults.length > 0 && (
                    <>
                        <button onClick={handleClearSearch} className="clear-button">Clear</button>
                        <label>
                            <input type="checkbox" checked={isExtension} onChange={(e) => setIsExtension(e.target.checked)} style={{ marginRight: "8px" }} />
                            Extension
                        </label>
                        {isExtension && (
                            <select value={searchExtension} onChange={(e) => setSearchExtension(e.target.value)} style={{ marginRight: "20px" }} >
                                <option value="">Select Playlist to Extend On</option>
                                {playlists
                                    .filter((playlist) => !playlistsExtension.some((ext) => ext.subjectExtend === playlist.id))
                                    .map((playlist) => (
                                        <option key={playlist.id} value={playlist.id}>
                                            {playlist.name}
                                        </option>
                                    ))}
                            </select>
                        )}
                    </>
                )}
            </div>

            <div>
                {loading && !searchResults.length && (
                    <div className="loader-container">
                        <PulseLoader color="#0b7aff" />
                    </div>
                )}

                {searchResults.length > 0 && (
                    <table className="table-container">
                        <thead>
                            <tr>
                                <th className="table-id">ID</th>
                                <th className="table-instructor">Instructor</th>
                                <th className="table-name">Name</th>
                                <th className="table-add">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchResults.map((playlist) => (
                                <tr key={playlist.id}>
                                    <td className="table-id">{playlist.id}</td>
                                    <td className="table-instructor">{playlist.instructor}</td>
                                    <td>{playlist.name}</td>
                                    <td className="table-add">
                                        <button className="playlist-add-button" onClick={() => handleAdd(playlist.id)}>Add</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default PlaylistTable;
